<template>
  <div>
    <div class="bg-white">
      <div class="bg-orange guest-form-header">
        <div class="text-title --white text-center font-weight-bold pt-3 pb-3">
          {{ $t("common.contactTitle") }}
        </div>
      </div>
      <div class="px-4 mb-4 text-title" v-if="!loading">

        <div class="font-weight-bold pt-6 pb-2 --prm">
          {{ $t("common.membersName") }}
        </div>
        <div class="font-weight-bold pb-2 --dark">
          {{ membersName }}
        </div>
        <div class="font-weight-bold pb-2 --prm">
          {{ $t("common.emailAddress") }}
        </div>
        <div class="pb-2 --dark">
          {{ emailAddress }}
        </div>
        <div class="font-weight-bold pb-3 --prm">
          {{ $t("common.phoneNumber") }}
        </div>
        <div class="pb-2 --dark">
          {{ phoneNumber }}
        </div>
        <div class="font-weight-bold pb-1 --prm">
          {{ $t("common.address") }}
        </div>
        <div class="pb-7 --dark">
          {{ address }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('loadUserInfo')
      })
    })
    this.loading = false
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    userInfo () { return this.$store.state.user.userInfo },
    membersName () { return this.userInfo.name },
    emailAddress () { return this.userInfo.contactEmail || this.userInfo.email },
    phoneNumber () { return this.userInfo.tel || this.userInfo.mobile },
    address () { return this.userInfo.address }
  }
}
</script>
